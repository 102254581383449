.graph {
	background-color: #F7FAFA;
	width: 100%;
	padding: 15px 25px 7px 25px;
	border-radius: 8px;
	display: relative;
	max-width: 100%;
}

@media print {
	.graph {
		margin-top: -10px;
	}

	canvas {
		min-height: 100%;
		max-width: 100%;
		max-height: 100%;
		height: auto !important;
		width: auto !important;
		background-color: transparent !important;
    }
}

