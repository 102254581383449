html {
	height: 100%;
}
body {
	height: 100%;
	margin: 0;
	font-family: sans-serif;
}
#root {
	height: 100%;	
}
