.funnel {
	display: flex;
	gap: 20px;
	gap: 10px;
	margin-top: 10px;
	align-items: center;
}

.card {
	background-color: #F7FAFA;
	width: 100%;
	padding: 15px 25px 7px 25px;
	border-radius: 8px;
	position: relative;
}

.cardWrapper {
	color: inherit;
}

.cardHead {
	display: flex;
}

.cardTitle {
	opacity: 1;
	color: #a1a1a1;
	font-size: 14px;
}

.cardNumbersWrapper {
	display: flex;
	justify-content: space-between;
}

.cardNumber {
	font-size: 30px;
}

.cardNumberComparison {
	font-size: 20px;
	opacity: 0.4;
	display: none;
}

.cardChange {
	background-color: #D3F3CD;
	color: #2F634A;
	margin-left: 14px;
	border-radius: 4px;
	padding-left: 4px;
	padding-right: 4px;
	font-size: 11px;
	height: 15px;
	padding-top: 0px;
	padding-bottom: 2px;
	margin-top: 2px;
	cursor: pointer;
}

.cardChangeNegative {
	background-color: #f3cdcd !important;
	color: #632f2f !important;
}

.steps {
	border-radius: 3px;
	padding: 1px 5px 1px 5px;
	font-size: 13px;
	color: rgba(121, 121, 121, 0.654);
	background-color: #F7FAFA;
	background-color: #f0f3f3;
	position: relative;
}

.steps:after {
	content: '';
	position: absolute;
	width: 10px;
	height: 2px;
	background-color: #F7FAFA;
	background-color: #e7ebeb;
	margin-left: 5px;
	top: calc(50% - 1px);
}

.steps:before {
	content: '';
	position: absolute;
	width: 10px;
	height: 2px;
	background-color: #F7FAFA;
	background-color: #e7ebeb;
	top: calc(50% - 1px);
	left: -10px;
}

.separator {
	background-color: #f0f3f3;
	max-width: 2px;
	min-width: 2px;
	border-radius: 10px;
	height: 85px;
}

.colorBubble {
	width: 10px;
	height: 10px;
	display: inline-block;
	background-color: #afffff;
	margin-right: 5px;
	border-radius: 100%;
}

.value {
	color:rgba(148, 148, 148, 0.654);
	display: block;
	font-size: 11px;
	margin-top: -6px;
}

@media print {
	.card {
		padding: 10px;
	}
	.cardNumber {
		font-size: 16px;
	}
	.cardTitle {
		font-size: 10px;
	}
	.cardChange {
		background-color: #D3F3CD;
		color: #2F634A;
		margin-left: 10px;
		border-radius: 3px;
		padding-left: 3px;
		padding-right: 3px;
		font-size: 7px;
		height: 11px;
		padding-top: 1px;
		padding-bottom: 1px;
	}
	.funnel {
		gap: 5px;
	}
	.steps {
		padding: 1px 3px 1px 3px;
		font-size: 8px;
	}
	.steps:after {
		content: '';
		position: absolute;
		width: 5px;
		height: 1px;
		background-color: #F7FAFA;
		background-color: #e7ebeb;
		margin-left: 1px;
		top: calc(50%);
		right: -5px;
	}
	
	.steps:before {
		content: '';
		position: absolute;
		width: 5px;
		height: 1px;
		background-color: #F7FAFA;
		background-color: #e7ebeb;
		top: calc(50%);
		left: -5px;
	}

	.separator {
		height: 50px;
		max-width: 1px;
		min-width: 1px;
	}

	.colorBubble {
		width: 4px;
		height: 4px;
		margin-right: 3px;
		margin-left: -4px;
		vertical-align: 1px;
	}

	.value {
		margin-top: -3px;
		font-size: 6px;
	}
}
