.panel {
	background-color: #fff;
	text-align: left;
	padding: 30px;
	color: #333;
}

@media print {
	body * {
		visibility: hidden;
	}
	.panel, .panel * {
		visibility: visible;
	}
	.panel {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
	}
}

.logo {
	height: 30px;
	width: 103px;
	background-image: url('./assets/radar-logo.png');
	background-size: auto 30px;
	background-color: transparent;
}

.logoWrapper {
	width: 200px;
}

.logoSubtitle {
	color: rgb(190, 190, 190);
	font-size: 10px;
	padding-left: 36px;
}

.header {
	display: flex;
	margin-bottom: 30px;
}

.dateSelector {
	background-color: rgb(248, 248, 248);
	border-radius: 4px;
	/* padding: 10px;
	padding-left: 100px;
	padding-right: 100px;
	padding-top: 12px; */
	padding-top: 5px;
	display: flex;
	align-items: center;
	padding-bottom: 5px;
}

.section {
	margin-bottom: 50px;
}

.sectionTitle {
	font-size: 19px;
	font-weight: 400;
	width: calc(100% + 3px);
	border-bottom: 1px solid rgb(238, 238, 238);
	padding-bottom: 3px;
	padding-left: 3px;
	margin-left: -3px;
}

.sectionContent {
	padding: 3px;
}

.footer {
	color: rgb(217, 217, 217);
	text-align: center;
	font-size: 14px;
	padding-top: 20px;
	display: none;
}

.datesString {
	font-size: 10px;
	opacity: 0.6;
	margin-left: 10px;
	vertical-align: 1.5px;
}

.datePicker {
	margin-left: 10px;
	margin-right: 10px;
	display: inline-block;
	padding: 0 !important;
}

.go {
	display: inline-block;
	margin-left: 10px;
	margin-right: 20px;
	background-color: rgb(78, 78, 78);
	padding: 5px;
	border-radius: 3px;
	color: #fff;
	padding-left: 10px;
	padding-right: 10px;
	cursor: pointer;
	transition: opacity 0.3s;
	display: none;
}

.go:hover {
	opacity: 0.9;
}

.selectedDate {
	display: inline;
	font-size: 10px;
}

@media print {
	.section {
		margin-bottom: 20px;
	}

	.sectionTitle {
		font-size: 14px;
	}

	.dateSelector {
		display: none;
	}

	.footer {
		font-size: 7px;
		padding-top: 14px;
		display: block;
	}

	.selectedDate {
		vertical-align: -1px;
	}
}
